import * as React from "react"
import {css} from "@emotion/react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import ContentWrapper from "../components/wrappers/contentWrapper";
import {Link} from "gatsby";
import Heading from "../components/typography/heading";

const MainMenu = [
  {
    title: "Home",
    alt: "Bookkeeping Services",
    url: "/",
  },
  {
    title: "Bookkeeping Services",
    alt: "General Bookkeeping Services",
    url: "/services/",
    subMenu: [
      {
        title: "Quickbooks ProAdvising",
        alt: "Quickbooks Online ProAdvising",
        url: "/services/quickbooks-online-proadvisor/",
      },
      {
        title: "Accounts Payable",
        alt: "Accounts Payable",
        url: "/services/accounts-payable/",
      },
      {
        title: "Accounts Receivable",
        alt: "Accounts Receivable",
        url: "/services/accounts-receivable/",
      },
      {
        title: "Payroll Services",
        alt: "Payroll Services",
        url: "/services/payroll-services/",
      },
    ],
  },
  {
    title: "Contact",
    alt: "Contact Labelle Bookkeeping",
    url: "/contact/",
    id: "003",
    order: 3,
  },
]

const SitemapPage = () => {
  return (
    <Layout>
      <Seo title="Sitemap for Labelle Bookkeeping"
           description="Sitemap for Labelle Bookkeeping"
           canonical="https://labellebookkeeping.com/sitemap/"
      />
      <ContentWrapper>
        <Heading>
          Sitemap for Labelle Bookkeeping
        </Heading>
        <div css={css`
          list-style-type: none;
          padding-left: 0;

          li {
            margin: 16px 0;

            a {
              color: var(--black);
              font-size: 1em;

              @media (min-width: 768px) {
                font-size: 18px;
              }
            }

            a:hover {
              text-decoration: underline;
              font-weight: bold;
            }

            a:visited {
              color: var(--black);
            }
          }

          //submenu styling
          ul li {
            list-style-type: none;
            margin: 16px 0;
            padding-left: 0;
          }
        `}>
          {MainMenu.map((menuItem, i) => {
            if (menuItem.subMenu) {
              return (
                <ul>
                  <li>
                    <Link to={menuItem.url}
                          title={menuItem.alt}>
                      {menuItem.title}
                    </Link>
                  </li>
                  <ul key={i}>
                    {menuItem.subMenu.map((subMenu, i) => {
                      return (
                        <>
                          <li>
                            <Link to={subMenu.url}
                                  title={subMenu.alt}>
                              {subMenu.title}
                            </Link>
                          </li>
                        </>
                      )
                    })}
                  </ul>
                </ul>
              )
            } else {
              return (
                <ul key={i}>
                  <li>
                    <Link to={menuItem.url}
                          title={menuItem.alt}>
                      {menuItem.title}
                    </Link>
                  </li>
                </ul>
              )
            }
          })}
        </div>
      </ContentWrapper>
    </Layout>
  )
}

export default SitemapPage